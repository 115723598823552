import React, { useState, useEffect, useContext } from "react"
import { Link, navigate } from "gatsby"
import { Formik, Form } from "formik"
import classNames from "classnames"

import AuthForm from "./AuthForm"
import Layout from "layout"
import Button from "elements/Button"
import Message from "elements/Message"
import Container from "layout/Container"

import Card from "elements/Card"

import styles from "./utils/auth.module.scss"

import { authValidationSchema } from "./utils/authValidationSchema"
import { AppContext } from "context/AppContext"
import { isBrowser } from "services/general"
import { handleAuthSubmit } from "./services/auth"
import { hasSignedInUser } from "./services/user"
import Modal from "../Elements/Modal"

const Auth = (props) => {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState({})
  const { state, dispatch } = useContext(AppContext)

  const redirectTo = props?.location?.state?.redirectTo
  let { pageContext, location } = props
  let { module } = pageContext

  useEffect(() => {
    if (isBrowser()) {
      let params = new URLSearchParams(location.search)
      let programChosen = params.get("program")

      if (programChosen) {
        localStorage.setItem("programChosen", programChosen)
      }
    }
    //eslint-disable-next-line
  }, [])

  const handleSubmit = (values, { resetForm }) => {
    setMessage({})
    setLoading(true)

    let payload = {
      values,
      resetForm,
      setMessage,
      setLoading,
      module: module.name,
      redirectTo: redirectTo,
    }
    handleAuthSubmit({ payload, state, dispatch, location })
  }

  const hideConditionsInclusions = ["sign-in", "access-code"]

  useEffect(() => {
    if (isBrowser()) {
      if (hasSignedInUser() && module.name !== "access-code") navigate("/")

      if (!state.auth.hasBeenVerified && module.name === "sign-up")
        navigate("/verify-email")
      if (state.auth.hasBeenVerified && module.name === "sign-in") {
        setMessage({
          type: "danger",
          content:
            "This email is already a registered MedGrocer account. Please sign in using these credentials.",
        })

        dispatch({ type: "RESET_DETAILS" })
      }
    }
  }, [state.auth.hasBeenVerified, module.name, dispatch])

  return (
    <Layout
      background="light-teal"
      seoTitle={module.seoTitle}
      display={{ footer: true, helpCenterBanner: false }}
      pageContext={pageContext}
      isAuth={true}
      isPolygonTeal={true}
      polygonStyle="authPages"
    >
      <Container isCentered tablet={8} desktop={6} fullhd={4}>
        <Modal />
        <Card className="card--mobile card-space" title={module?.title}>
          {redirectTo && (
            <Message color="warning">
              Sign in to your account to continue.
            </Message>
          )}
          <Formik
            initialValues={state.auth}
            validationSchema={authValidationSchema(module.name)}
            onSubmit={handleSubmit}
          >
            {({ values }) => (
              <Form>
                <AuthForm
                  module={module.name}
                  formFields={pageContext.formFields}
                />
                {module.name === "sign-in" && (
                  <p className="pb-1 pt-0 has-text-weight-bold">
                    <Link to="/forgot-password">Forgot password</Link>
                  </p>
                )}
                {message?.content && (
                  <Message color={message?.type}>
                    {message?.content?.message || message?.content}
                  </Message>
                )}
                <div className="is-flex is-justify-content-center">
                  <Button
                    type="submit"
                    color="secondary"
                    disabled={loading}
                    className={classNames("mt-2", styles["button"])}
                    isLoading={loading}
                  >
                    {module.cta}
                  </Button>
                </div>
                {module.name === "sign-in" && (
                  <section>
                    <p className="has-text-centered pt-1">
                      Don't have an account yet?{" "}
                      <Link to="/verify-email">
                        <strong className="has-text-secondary">
                          Create new account
                        </strong>
                      </Link>
                    </p>
                  </section>
                )}
                {!hideConditionsInclusions.includes(module.name) && (
                  <section>
                    <p className="has-text-centered pt-1">
                      By signing up, you agree to our{" "}
                      <Link
                        to={`/terms-and-conditions`}
                        className="has-text-secondary"
                      >
                        Terms and Conditions
                      </Link>{" "}
                      and{" "}
                      <Link
                        to={`/privacy-policy`}
                        className="has-text-secondary"
                      >
                        Privacy Policy
                      </Link>
                    </p>
                    <p className="has-text-centered mt-2">
                      Already have an account?{" "}
                      <Link
                        to="/sign-in"
                        state={{ redirectTo }}
                        className="has-text-secondary"
                      >
                        Sign In.
                      </Link>
                    </p>
                  </section>
                )}
              </Form>
            )}
          </Formik>
        </Card>
      </Container>
    </Layout>
  )
}

export default Auth
