import { navigate } from "gatsby"

import { handleEmailLogin } from "./signin"
import { checkIfEmailAlreadyExists, handleSignUp } from "./signup"
import { handleForgotPassword } from "./forgotPassword"
import { isAccessCodeValid } from "./permissions"

import { isBrowser } from "../../../services/general"

import { getSignedInUser } from "./user"

const { userData } = getSignedInUser()

export const handleAuthSubmit = async ({
  payload,
  state,
  dispatch,
  location,
}) => {
  let { setLoading, setMessage } = payload
  let email = payload.values?.email

  switch (payload.module) {
    case "sign-in":
      handleEmailLogin({
        ...payload,
        callBack: () => {
          setLoading(false)

          dispatch({
            type: "SAVE_AUTH",
            payload: {
              ...payload.values,
              // email: transformedEmail,
              hasBeenVerified: true,
            },
          })

          dispatch({
            type: "SAVE_CONTEXT_TO_SESSION",
            payload: {
              ...state,
              auth: {
                ...payload.values,
                // email: transformedEmail,
                hasBeenVerified: true,
              },
            },
          })
        },
        errorCallback: () => {
          setLoading(false)
        },
        dispatch,
        location,
      })
      break
    case "forgot-password":
      handleForgotPassword({
        ...payload,
        location,
        callback: () => {
          setLoading(false)
          setMessage({
            content:
              "Please check your email for instructions to reset your password.",
          })
        },
        errorCallback: () => {
          setLoading(false)
        },
      })
      break
    case "verify-email":
      const isAlreadyEnrolled = await checkIfEmailAlreadyExists(email)
      dispatch({
        type: "SAVE_AUTH",
        payload: { ...payload.values, hasBeenVerified: true },
      })

      dispatch({
        type: "SAVE_CONTEXT_TO_SESSION",
        payload: {
          ...state,
          auth: { ...payload.values, hasBeenVerified: true },
        },
      })

      if (isAlreadyEnrolled) {
        payload.redirectTo
          ? navigate("/sign-in", { state: { redirectTo: payload.redirectTo } })
          : navigate("/sign-in")
      } else {
        sessionStorage.setItem("redirectTo", payload.redirectTo)
        navigate("/sign-up")
      }

      setLoading(false)
      break
    case "sign-up":
      handleSignUp({
        ...payload,
        dispatch,
        location,
        callBack: () => {
          setLoading(false)
          navigate("/sign-in")
          dispatch({ type: "RESET_DETAILS" })
        },
        errorCallback: () => {
          setLoading(false)
        },
      })
      break

    case "access-code":
      const {
        validity,
        siteOptions,
        eligiblePrograms,
      } = await isAccessCodeValid(payload.values.accessCode)

      if (isBrowser()) {
        sessionStorage.setItem(
          "userData",
          JSON.stringify({
            ...userData,
            domain: {
              validity,
              eligiblePrograms,
              siteOptions,
            },
          })
        )
      }
      if (validity === "active" || validity === true) {
        await dispatch({
          type: "SAVE_ENROLLMENT",
          payload: {
            ...payload.values,
            siteOptions,
          },
        })
        await dispatch({
          type: "SAVE_CONTEXT_TO_SESSION",
          payload: {
            ...state,
            userData: {
              domain: { eligiblePrograms, ...state?.userData?.domain },
              ...state.userData,
            },
            enrollment: {
              ...state.enrollment,
              accessCode: payload.values.accessCode,
              siteOptions: siteOptions,
            },
            // auth: { ...values.auth },
          },
        })
        navigate("/enrollment")
      } else if (validity === "inactive") {
        setMessage({
          type: "danger",
          content:
            "Access code is no longer active, please make sure you that you are using the correct access code provided by your company.",
        })
      } else {
        setMessage({
          type: "danger",
          content:
            "Access code not found, please make sure you that you are using the correct access code provided by your company.",
        })
      }

      setLoading(false)

      break

    default:
      break
  }
}
